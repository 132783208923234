import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

/**
 * To preload a specific route, use:
 *  /* webpackPrefetch: true */

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "FrontendLoad",
        component: () =>
            import(
                /* webpackChunkName: "FrontendLoad" */ "../views/frontend/Load.vue"
            ),
        meta: {
            title: "Innojo",
            auth: false,
            layout: "none",
        },
    },
    {
        path: "/h",
        name: "FrontendHome",
        component: () =>
            import(
                /* webpackChunkName: "FrontendHome" */ "../views/frontend/Home.vue"
            ),
        meta: {
            title: "Innojo",
            auth: false,
            layout: "frontend",
        },
    },
    {
        path: "/c",
        name: "ProgramCountrySelector",
        component: () =>
            import(
                /* webpackChunkName: "ProgramCountrySelector" */ "../views/frontend/Program/CountrySelector.vue"
            ),
        meta: {
            title: "Program Country Selector",
            auth: false,
            layout: "none",
        },
    },
    {
        path: "/c/cl/:countryCode",
        name: "ProgramCountryLocations",
        component: () =>
            import(
                /* webpackChunkName: "ProgramCountryLocations" */ "../views/frontend/Program/CountryLocations.vue"
            ),
        meta: {
            title: "Country Locations",
            auth: false,
            layout: "none",
        },
    },
    {
        path: "/c/ps/:uuid",
        name: "ProgramSessions",
        component: () =>
            import(
                /* webpackChunkName: "ProgramSessions" */ "../views/frontend/Program/Sessions.vue"
            ),
        meta: {
            title: "Locatioin Sessions",
            auth: false,
            layout: "none",
        },
    },
    {
        path: "/about",
        name: "FrontendAbout",
        component: () =>
            import(
                /* webpackChunkName: "FrontendAbout" */ "../views/frontend/About.vue"
            ),
        meta: {
            title: "Innojo",
            auth: false,
            layout: "frontend",
        },
    },
    {
        path: "/terms",
        name: "FrontendTerms",
        component: () =>
            import(
                /* webpackChunkName: "FrontendTerms" */ "../views/frontend/Terms.vue"
            ),
        meta: {
            title: "Innojo",
            auth: false,
            layout: "frontend",
        },
    },
    {
        path: "/registration",
        redirect: { name: "FrontendLogin" },
        name: "FrontendRegistration",
    },
    // {
    //     path: "/registration",
    //     name: "FrontendRegistration",
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "FrontendRegistration" */ "../views/frontend/Registration.vue"
    //         ),
    //     meta: {
    //         title: "Innojo",
    //         auth: false,
    //         layout: "frontend",
    //     },
    // },
    // {
    //     path: "/registration/thanks",
    //     name: "FrontendRegistrationThanks",
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "FrontendRegistrationThanks" */ "../views/frontend/RegistrationThanks.vue"
    //         ),
    //     meta: {
    //         title: "Innojo",
    //         auth: false,
    //         layout: "frontend",
    //     },
    // },
    // {
    //     path: "/registration/confirm/:confirmationCode",
    //     name: "FrontendRegistrationConfirm",
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "FrontendRegistrationConfirm" */ "../views/frontend/RegistrationConfirm.vue"
    //         ),
    //     meta: {
    //         title: "Innojo",
    //         auth: false,
    //         layout: "frontend",
    //     },
    // },
    {
        path: "/login",
        name: "FrontendLogin",
        component: () =>
            import(
                /* webpackChunkName: "FrontendLogin" */ "../views/frontend/Login.vue"
            ),
        meta: {
            title: "Innojo",
            auth: false,
            layout: "frontend",
        },
    },
    {
        path: "/logout",
        name: "FrontendLogout",
        component: () =>
            import(
                /* webpackChunkName: "FrontendLogout" */ "../views/frontend/Logout.vue"
            ),
        meta: {
            title: "Innojo",
            auth: false,
            layout: "none",
        },
    },
    {
        path: "/password/email/:email?",
        name: "FrontendPasswordEmail",
        component: () =>
            import(
                /* webpackChunkName: "FrontendPasswordEmail" */ "../views/frontend/PasswordEmail.vue"
            ),
        meta: {
            title: "Innojo",
            auth: false,
            layout: "frontend",
        },
    },
    {
        path: "/password/reset/:token/:email",
        name: "FrontendPasswordReset",
        component: () =>
            import(
                /* webpackChunkName: "FrontendPasswordReset" */ "../views/frontend/PasswordReset.vue"
            ),
        meta: {
            title: "Innojo",
            auth: false,
            layout: "frontend",
        },
    },
    {
        path: "/b/home",
        name: "BackendHome",
        meta: {
            title: "Home",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendHome" */ "../views/backend/Home/Home.vue"
            ),
    },
    {
        path: "/b/chat",
        name: "BackendChat",
        meta: {
            title: "Chat",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendChat" */ "../views/backend/Chat/Chat.vue"
            ),
    },
    {
        path: "/b/profile/edit/:username",
        name: "BackendProfileEdit",
        meta: {
            title: "Profile Edit",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendProfileEdit" */ "../views/backend/Profile/Edit.vue"
            ),
    },
    {
        path: "/b/profile/slack/messages",
        name: "BackendSlackMessages",
        meta: {
            title: "Slack Messages",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendSlackMessages" */ "../views/backend/Slack/Messages.vue"
            ),
    },
    {
        path: "/b/projects/list/:username",
        name: "BackendProjectList",
        meta: {
            title: "Project List",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendProjectList" */ "../views/backend/Project/List.vue"
            ),
    },
    {
        path: "/b/project/edit/:slug?",
        name: "BackendProjectEdit",
        meta: {
            title: "Project Edit",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendProjectEdit" */ "../views/backend/Project/Edit.vue"
            ),
    },
    {
        path: "/b/project/updates/:slug",
        name: "BackendProjectUpdates",
        meta: {
            title: "Project Updates",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendProjectUpdates" */ "../views/backend/Project/Updates.vue"
            ),
    },
    {
        path: "/f/profile/view/:username",
        name: "FrontendProfileView",
        meta: {
            title: "Profile View",
            auth: false,
            layout: "frontend",
        },
        component: () =>
            import(
                /* webpackChunkName: "FrontendProfileView" */ "../views/frontend/Profile/View.vue"
            ),
    },
    {
        path: "/f/profile/inaccessible",
        name: "FrontendProfileInaccessible",
        meta: {
            title: "Profile Inaccessible",
            auth: false,
            layout: "frontend",
        },
        component: () =>
            import(
                /* webpackChunkName: "FrontendProfileInaccessible" */ "../views/frontend/Profile/Inaccessible.vue"
            ),
    },
    {
        path: "/f/project/view/:slug",
        name: "FrontendProjectView",
        meta: {
            title: "Project View",
            auth: false,
            layout: "frontend",
        },
        component: () =>
            import(
                /* webpackChunkName: "FrontendProjectView" */ "../views/frontend/Project/View.vue"
            ),
    },
    {
        path: "/f/project/inaccessible",
        name: "FrontendProjectInaccessible",
        meta: {
            title: "Project Inaccessible",
            auth: false,
            layout: "frontend",
        },
        component: () =>
            import(
                /* webpackChunkName: "FrontendProjectInaccessible" */ "../views/frontend/Project/Inaccessible.vue"
            ),
    },
    {
        path: "/b/timeline/:username",
        name: "BackendProfileTimeline",
        meta: {
            title: "Timeline",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendProfileTimeline" */ "../views/backend/Timeline/Timeline.vue"
            ),
    },
    {
        path: "/b/connect/:type?",
        name: "BackendConnect",
        meta: {
            title: "Connect",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendConnect" */ "../views/backend/Connect/Connect.vue"
            ),
    },
    {
        path: "/b/profile/view/:username",
        name: "BackendProfileView",
        meta: {
            title: "Profile View",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendProfileView" */ "../views/backend/Profile/View.vue"
            ),
    },
    {
        path: "/b/project/view/:slug",
        name: "BackendProjectView",
        meta: {
            title: "Project View",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendProjectView" */ "../views/backend/Project/View.vue"
            ),
    },
    {
        path: "/b/tree",
        name: "BackendCoachingTreeLoader",
        meta: {
            title: "Coaching Tree Loader",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendCoachingTreeLoader" */ "../views/CoachingTree/CoachingTreeLoader.vue"
            ),
    },
    {
        path: "/b/tree/:treeUuid/tab/:tab",
        name: "BackendCoachingTree",
        meta: {
            title: "Coaching Tree",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendCoachingTree" */ "../views/CoachingTree/CoachingTree.vue"
            ),
    },
    {
        path: "/b/coachees",
        name: "BackendCoachees",
        meta: {
            title: "Coachees",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "Coachees" */ "../views/backend/Coachees/Coachees.vue"
            ),
    },
    {
        path: "/b/coachees/attendance/:agreementUuid",
        name: "BackendCoacheeAttendance",
        meta: {
            title: "Coachee Attendance",
            auth: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "BackendCoacheeAttendance" */ "../views/backend/Coachees/Attendance.vue"
            ),
    },
    {
        path: "/b/reporting",
        name: "CoachingReporting",
        meta: {
            title: "Coaching Reports",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "CoachingReporting" */ "../views/CoachingReporting/CoachingReporting.vue"
            ),
    },
    {
        path: "/b/admin/users",
        name: "Admin",
        meta: {
            title: "Admin",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "Admin" */ "../views/Admin/ViewUsers.vue"
            ),
    },
    {
        path: "/b/admin/cohorts",
        name: "Cohorts",
        meta: {
            title: "Cohorts",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "Cohorts" */ "../views/Admin/Cohort/List.vue"
            ),
    },

    {
        path: "/b/program/sessions",
        name: "BackendProgramSessions",
        meta: {
            title: "Program Sessions",
            auth: true,
            admin: false,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "ProgramsList" */ "../views/backend/Program/Sessions/List.vue"
            ),
    },
    {
        path: "/b/admin/programs",
        name: "Programs",
        meta: {
            title: "Programs",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "ProgramsList" */ "../views/Admin/Program/Programs/List.vue"
            ),
    },
    {
        path: "/b/admin/program/locations",
        name: "ProgramLocations",
        meta: {
            title: "Program Locations",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "ProgramLocations" */ "../views/Admin/Program/Locations/List.vue"
            ),
    },

    {
        path: "/b/reporting/edgeusers",
        name: "CoachingReportingEdgeUsers",
        meta: {
            title: "Edge Users",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "CoachingReportingEdgeUsers" */ "../views/CoachingReporting/Users/Edge.vue"
            ),
    },
    {
        path: "/b/reporting/users",
        name: "CoachingReportingUsers",
        meta: {
            title: "Coaching Users",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "CoachingReportingUsers" */ "../views/CoachingReporting/Users/Users.vue"
            ),
    },
    {
        path: "/b/reporting/agreements",
        name: "CoachingReportingAgreements",
        meta: {
            title: "Coaching Agreements",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "CoachingReportingAgreements" */ "../views/CoachingReporting/Agreements/Agreements.vue"
            ),
    },
    {
        path: "/b/reporting/tree/charts",
        name: "CoachingReportingTreeCharts",
        meta: {
            title: "Tree Charts",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "CoachingReportingTreeCharts" */ "../views/CoachingReporting/Tree/Charts.vue"
            ),
    },
    {
        path: "/b/reporting/tree/branches",
        name: "CoachingReportingTreeBranches",
        meta: {
            title: "Tree Branches",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "CoachingReportingTreeBranches" */ "../views/CoachingReporting/Tree/Branches.vue"
            ),
    },
    {
        path: "/b/reporting/searchlogs",
        name: "CoachingReportingSearchLogs",
        meta: {
            title: "Search Logs",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "CoachingReportingSearchLogs" */ "../views/CoachingReporting/Search/SearchLogs.vue"
            ),
    },
    {
        path: "/b/reporting/searchlogs/view",
        name: "CoachingReportingViewSearchLogs",
        meta: {
            title: "View Search Logs ",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "CoachingReportingViewSearchLogs" */ "../views/CoachingReporting/Search/ViewSearchLogs.vue"
            ),
    },
    {
        path: "/b/reporting/feedback/summary",
        name: "CoachingReportingFeedbackSummary",
        meta: {
            title: "Feedback Report Summary",
            auth: true,
            admin: true,
            layout: "backend",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "CoachingReportingFeedbackSummary" */ "../views/CoachingReporting/Feedback/Summary.vue"
            ),
    },
    {
        path: "/b/reporting/attendance/summary",
        name: "CoachingReportingAttendanceSummary",
        meta: {
            title: "Attendance Report Summary",
            auth: true,
            admin: true,
            layout: "backend",
        },
        component: () =>
            import(
                /* webpackChunkName: "CoachingReportingAttendanceSummary" */ "../views/CoachingReporting/Attendance/Summary.vue"
            ),
    },
    {
        path: "/b/debug/pusher",
        name: "DebugPusher",
        meta: {
            title: "Debug Pusher",
            auth: false,
            layout: "none",
        },
        component: () =>
            import(
                /* webpackChunkName: "DebugPusher" */ "../views/Debug/Pusher.vue"
            ),
    },
    {
        path: "/b/tools/whoisregistered",
        name: "CoachingReportingWhoIsRegistered",
        meta: {
            title: "Who Is Registered",
            auth: false,
            layout: "none",
        },
        component: () =>
            import(
                /* webpackChunkName: "CoachingReportingWhoIsRegistered" */ "../views/CoachingReporting/Tools/WhoIsRegistered.vue"
            ),
    },

    // {
    //     path: "/about",
    //     name: "About",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ "../views/About.vue"),
    // },
];

// const router = new VueRouter({
//     mode: "history",
//     base: process.env.BASE_URL,
//     routes,
// });

// export default router;

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    load();

    guard(to, from, next);
});

import store from "../store";
const load = async () => {
    //    await store.dispatch("sdgItems");
    // await store.dispatch("countryItems");

    //await store.dispatch("checkAuth");
    // await store.dispatch("rolesItems");
    if (store.state.auth.loggedIn.id) {
        await store.dispatch("myTreeActions");
        await store.dispatch("myTrees");
        await store.dispatch("slackUsers");
        await store.dispatch("slackChannels");
    }
};

let entryUrl = null;
const guard = async (to, from, next) => {
    if (store.state.auth.loggedIn.id) {
        if (entryUrl) {
            const url = entryUrl;
            entryUrl = null;
            return next(url); // goto stored url
        } else {
            return next(); // all is fine
        }
    }

    // beforeGuard() {
    //     return store.state.auth.roles.administrator;
    // },

    if (to.meta && to.meta.auth == false) {
        return next();
    }

    // Continue with dispatch not working

    // we use await as this async request has to finish
    // before we can be sure
    if (store.state.auth.loggedIn.id) {
        return next();
    } else {
        // The user is not logged in

        entryUrl = to.path; // store entry url before redirect

        // If they are trying to access a user profile, show them the public frontend version of it
        if (entryUrl.indexOf("/b/profile/") === 0) {
            router.push({
                name: "FrontendProfileView",
                params: { username: to.params.username },
            });
        } else if (entryUrl.indexOf("/b/project/") === 0) {
            router.push({
                name: "FrontendProjectView",
                params: { slug: to.params.slug },
            });
        } else {
            await store.dispatch("intended", to.fullPath);
            router.push({ name: "FrontendLogin" }); // Redirect to the main site login
        }
    }
};

/**
 * To solve an issue with chunk loading and patching, capture the error & reload the page.
 */
// https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
router.onError((error) => {
    if (error.name.indexOf("ChunkLoadError") >= 0) {
        // Force a reload so that the new .js / .css files can be loaded
        window.location.reload();
    }
    if (error.name.indexOf("NavigationDuplicated") >= 0) {
        // Same navigation clicked, so just catch & ignore this for now
    }
});

export default router;
