<script functional>
import { replaceEmojis } from "../../../../utils/string-functions";

export default {
  name: "slack-emoji",
  props: {
    block: {
      type: Object,
    },
  },
  render: function (createElement) {
    let self = this;
    if (self.$props.block.type == "emoji") {
      const text = replaceEmojis(":" + self.$props.block.name + ":");

      if (text && text.length) {
        return createElement("span", {}, text);
      }
      return null;
    } else {
      return null;
    }
  },
};
</script>
