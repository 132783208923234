<template functional>
  <span class="text-left" data-type="rich-text">
    <!-- RichText -->
    <span v-if="props.block.elements">
      <span v-for="(element, index) in props.block.elements" :key="index">
        <slack-type-selector :block="element"></slack-type-selector>
      </span>
    </span>

    <span v-else-if="props.block && props.block.type == 'rich_text'">
      <span class="text-left type-rich-text">
        <span v-if="props.block.type == 'text'">
          {{ props.block.text }}
        </span>
        <span v-if="props.block.type == 'link'">
          <a :href="props.block.url">{{ props.block.url }}</a>
        </span>
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: "slack-rich-text",
  props: {
    block: {
      type: Object,
    },
  },
};
</script>
