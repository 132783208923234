<template>
  <span class="text-left" v-if="block.type == 'user'" data-type="user">
    @{{ thisUser.name }}
  </span>
</template>

<script>
export default {
  name: "slack-user",
  props: {
    block: {
      type: Object,
    },
  },
  computed: {
    slackUsers() {
      return this.$store.state.slack.users;
    },
    thisUser() {
      return this.lookupSlackUserById(this.block.user_id);
    },
  },
  methods: {
    lookupSlackUserById(userId) {
      for (let i = 0; i < this.slackUsers.length; i++) {
        if (this.slackUsers[i].id === userId) {
          return this.slackUsers[i];
        }
      }
    },
  },
};
</script>
