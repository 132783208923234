<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      @click:outside="dialog = false"
      max-width="600"
      v-model="dialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="pink lighten-3" dark class="mb-5"
            ><v-icon class="white--text mr-1" small>{{
              icons.mdiHelpCircleOutline
            }}</v-icon>
            How would you like to receive help?</v-toolbar
          >
          <v-card-text>
            <v-row class="mb-3">
              <v-col xs="12">
                <v-btn @click="emailUs">
                  <v-icon class="green--text mr-1">{{ icons.mdiEmail }}</v-icon>
                  Private email
                </v-btn>
              </v-col>
              <v-col xs="12">
                <v-btn @click="slackUs">
                  <v-icon class="red--text mr-1">{{ icons.mdiSlack }}</v-icon>
                  Public Slack channel
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="pa-0 ma-0 mb-2" align="center" justify="center">
              <h3 class="text-left font-weight-light">
                <v-avatar class="mr-1 ml-1" style="width: 48px; height: 48px">
                  <v-icon class="pink--text icons">{{
                    icons.mdiTelevisionPlay
                  }}</v-icon>
                </v-avatar>
                Instructional videos
              </h3>
            </v-row>
            <v-row align="center" justify="center" class="mb-2">
              <popup src="https://www.youtube.com/embed/IOpP_9CLs9U">
                <v-btn small outlined>About Innojo </v-btn>
              </popup>
            </v-row>
            <v-row align="center" justify="center" class="mb-2">
              <popup src="https://www.youtube.com/embed/jHlUHtKhypE">
                <v-btn small outlined>Creating your Digital Identity </v-btn>
              </popup>
            </v-row>
            <v-row align="center" justify="center" class="mb-2">
              <popup src="https://www.youtube.com/embed/gNG78oPKlWk">
                <v-btn small outlined>Journey tree intro</v-btn>
              </popup>
            </v-row>
            <v-row align="center" justify="center" class="mb-2">
              <popup src="https://www.youtube.com/embed/VNK-kqoA4Os">
                <v-btn small outlined>Journey tree and timeline </v-btn>
              </popup>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiEmail,
  mdiSlack,
  mdiHelpCircleOutline,
  mdiTelevisionPlay,
} from "@mdi/js";
import Popup from "../../components/Popup/Popup";

export default {
  components: {
    Popup,
  },
  data: () => ({
    icons: {
      mdiEmail,
      mdiSlack,
      mdiHelpCircleOutline,
      mdiTelevisionPlay,
    },
    dialog: false,
  }),
  computed: {
    slackHelpChannel() {
      return this.$store.state.slack.channels.find(
        (element) => element.name == "help"
      );
    },
  },

  methods: {
    emailUs() {
      window.open("mailto:hello@innojo.me", "_blank");
    },
    slackUs() {
      window.open(
        "slack://channel?team=" +
          process.env.VUE_APP_SLACK_WORKSPACE_TEAM_ID +
          "&id=" +
          this.slackHelpChannel.id,
        "_blank"
      );
    },
  },
};
</script>
