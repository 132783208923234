<template functional>
  <span class="text-left" data-type="link" v-if="props.block.type == 'link'">
    <a :href="props.block.url" class="d-inline-block pb-1">{{
      props.block.url | truncate(32, "..")
    }}</a>
  </span>
</template>

<script>
export default {
  name: "slack-text",
  props: {
    block: {
      type: Object,
    },
  },
};
</script>
