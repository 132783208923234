<script functional>
import { replaceEmojis } from "../../../../utils/string-functions";

export default {
  name: "slack-text",
  props: {
    block: {
      type: Object,
    },
  },
  render: function (createElement) {
    // if there are emojis, we need to replace them
    this.$props.block.text = replaceEmojis(this.$props.block.text);

    return createElement("span", {}, this.$props.block.text);
  },
};
</script>
