<template functional>
  <span>
    <component
      v-if="props.block.type == 'user'"
      :is="injections.components.User"
      :block="props.block"
    ></component>
    <component
      v-if="props.block.type == 'text'"
      :is="injections.components.Text"
      :block="props.block"
    ></component>
    <component
      v-if="props.block.type == 'link'"
      :is="injections.components.Link"
      :block="props.block"
    ></component>
    <component
      v-if="props.block.type == 'rich_text'"
      :is="injections.components.RichText"
      :block="props.block"
    ></component>
    <component
      v-if="props.block.type == 'section'"
      :is="injections.components.Section"
      :block="props.block"
    ></component>
    <component
      v-if="props.block.type == 'rich_text_section'"
      :is="injections.components.RichTextSection"
      :block="props.block"
    ></component>
    <component
      v-if="props.block.type == 'message'"
      :is="injections.components.Message"
      :block="props.block"
    ></component>
    <component
      v-if="props.block.type == 'emoji'"
      :is="injections.components.Emoji"
      :block="props.block"
    ></component>

    <!-- <slack-rich-text
      v-else-if="props.block.type == 'rich_text'"
      :block="block"
    ></slack-rich-text>
    <slack-section
      v-else-if="props.block.type == 'section'"
      :block="block"
    ></slack-section>
    <slack-rich-text-section
      v-else-if="props.block.type == 'rich_text_section'"
      :block="block"
    ></slack-rich-text-section> -->
  </span>
</template>

<script>
import User from "./Types/User.vue";
import Link from "./Types/Link.vue";
import Text from "./Types/Text.vue";
import RichText from "./Types/RichText.vue";
import Section from "./Types/Section.vue";
import RichTextSection from "./Types/RichTextSection.vue";
import Message from "./Types/Message.vue";
import Emoji from "./Types/Emoji.vue";

export default {
  name: "slack-type-selector",
  inject: {
    components: {
      default: {
        User,
        Link,
        Text,
        RichText,
        Section,
        RichTextSection,
        Message,
        Emoji,
      },
    },
  },
  // components: {
  //   "slack-text": Text,
  //   // "slack-section": Section,
  //   // "slack-rich-text": RichText,
  //   // "slack-rich-text-section": RichTextSectionVue,
  // },
  props: {
    block: {
      type: Object,
    },
  },
};
</script>
