import Vue from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { getMonthAsString } from "../../../v2/src/utils/date-functions";
dayjs.extend(relativeTime);

Vue.filter("diffForHumans", function (date) {
    if (!date) {
        return date;
    }
    return dayjs(date).fromNow();
});

// Takes a date in the format yyyy-mm-dd or yyyy-mm-ddTH:i:s and writes it as d M Y
Vue.filter("dayMonthYear", function (date) {
    if (!date) {
        return date;
    }

    const parts = date.split("T");
    const dateparts = parts[0].split("-");

    const yearAsNumber = parseInt(dateparts[0]);
    const monthAsNumber = parseInt(dateparts[1]);
    const dayAsNumber = parseInt(dateparts[2]);

    return (
        dayAsNumber + " " + getMonthAsString(monthAsNumber) + " " + yearAsNumber
    );
});

// Takes a date in the format yyyy-mm-dd H:i:s and writes it as d M Y H:s
Vue.filter("dayMonthYearHourMinute", function (date) {
    if (!date) {
        return date;
    }

    const parts = date.split(" ");
    const dateparts = parts[0].split("-");
    const timeparts = parts[1].split(":");

    const yearAsNumber = parseInt(dateparts[0]);
    const monthAsNumber = parseInt(dateparts[1]);
    const dayAsNumber = parseInt(dateparts[2]);

    const hourAsNumber = timeparts[0].padStart(2, "0");
    const minuteAsNumber = timeparts[1].padStart(2, "0");

    return (
        dayAsNumber +
        " " +
        getMonthAsString(monthAsNumber) +
        " " +
        yearAsNumber +
        " " +
        hourAsNumber +
        ":" +
        minuteAsNumber
    );
});

Vue.filter(
    "truncateWords",
    function (description, length = 20, suffix = "...") {
        const value = description.split(" ");

        if (value)
            return value.length > length
                ? value.slice(0, length).join(" ") + suffix
                : value.join(" ");
        return null;
    }
);

Vue.filter("truncate", function (title, length = 20, suffix = "...") {
    if (!title) {
        return null;
    }

    if (title.length < length) {
        return title;
    }
    return title.substring(0, length) + suffix;
});
