<template functional>
  <span
    class="text-left"
    data-type="message"
    v-if="props.block.type == 'message'"
  >
    <span v-if="props.block.subtype && props.block.subtype == 'channel_name'">
      renamed the channel from "{{ props.block.old_name }}" to "{{
        props.block.name
      }}"
    </span>
    <span
      v-else-if="props.block.subtype && props.block.subtype == 'channel_join'"
    >
      has joined the channel
    </span>
    <span v-else>
      {{ props.block.message }}
    </span>
  </span>
</template>

<script>
export default {
  name: "slack-message",
  props: {
    block: {
      type: Object,
    },
  },
};
</script>
