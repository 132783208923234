<template>
  <div class="text-center">
    <v-snackbar top right v-model="snackbar" :timeout="timeout" :color="color">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    type: "success",
    snackbar: false,
    text: "Default text for snackbar",
    timeout: 1000,
  }),
  computed: {
    color: function () {
      let val = "success";
      if (this.type == "success") {
        val = "success";
      } else if (this.type == "error") {
        val = "error";
      } else if (this.type == "info") {
        val = "info";
      }
      return val;
    },
  },
  created() {
    let self = this;
    this.$eventHub.$on(
      "message",
      function (text, type = "success", timeout = null) {
        if (!timeout) {
          timeout = 2000;
          if (type == "error") {
            timeout = 10000;
          }
        }
        self.text = text;
        self.type = type;
        self.timeout = timeout;
        self.snackbar = true;
      }
    );
  },
};
</script>
