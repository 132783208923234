<template>
  <!-- Creates a popup where the slot is used for the clickable text that launches the popup, and one can use it for launching both youtube videos and text -->
  <div>
    <div @click="dialog = true" class="pointer">
      <slot></slot>
    </div>

    <v-dialog
      v-model="dialog"
      @click:outside="dialog = false"
      :max-width="width + 42"
    >
      <v-card>
        <v-card-title class="headline d-flex flex-row-reverse">
          <v-btn
            class="teal--text"
            @click="dialog = false"
            elevation="0"
            fab
            x-small
            >X</v-btn
          >
        </v-card-title>

        <v-card-text>
          <div v-if="type == 'iframe'">
            <iframe
              :width="width"
              :height="height"
              :src="src"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div v-else-if="type == 'text'">
            {{ text }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    src: { default: null }, // If we're launching a youtube video, what's the source
    type: { default: "iframe" },
    text: { default: null },
    width: {
      default: 560,
    },
    height: {
      default: 315,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
