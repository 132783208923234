<template>
  <v-app>
    <component v-if="loaded" :is="layout"> </component>
  </v-app>
</template>

<script>
const default_layout = "frontend";

export default {
  computed: {
    loaded() {
      return this.$route.meta.layout ? true : false;
    },
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>
