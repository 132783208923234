/* API Helper functions */

/**
 * Get a resized image back from the cloudfront service.  If height=0, then height becomes a variable and width stays static, and vica versa.
 * @param  {[type]} src    The location of the image on the s3 bucket.  If src starts with 'http', an external image is referenced.
 * @param  {[type]} width  The desired width
 * @param  {[type]} height Th desired height
 */
export const getResizedImage = function (
    src,
    width = 0,
    height = 0,
    params = ["c_fill", "f_auto"]
) {
    if (!src) {
        src = "no-image-available.png";
    }
    const imageManipulationService =
        "https://res.cloudinary.com/innojo/image/fetch";
    const quality = 100;

    let req = imageManipulationService;

    req += "/q_" + quality;

    if (width) {
        req += ",w_" + width;
    }
    if (height) {
        req += ",h_" + height;
    }

    for (let i = 0; i < params.length; i++) {
        req += "," + params[i];
    }

    req += "/";

    if (src.substring(0, 4) === "http") {
        req += src;
    } else {
        req +=
            "https://" +
            process.env.VUE_APP_AWS_BUCKET +
            ".s3.amazonaws.com/" +
            src;
    }

    return req;
};

/**
 * Takes a Date() object and returns a common format
 */
export const getFormattedDate = function (date) {
    if (!date) return null;
    const datePart = date.split("T");
    const [year, month, day] = datePart[0].split("-");
    const monthsAsArray =
        "January,February,March,April,May,June,July,August,September,October,November,December".split(
            ","
        );
    const monthAsString = monthsAsArray[parseInt(month) - 1];
    return `${day} ${monthAsString} ${year}`;
};

export const getErrorMessages = function (error) {
    if (error.response && error.response.data && error.response.data.errors) {
        return error.response.data.errors;
    }
};
export const getLocalErrorMessages = function (field, errors) {
    if (errors && field in errors) {
        const err = JSON.parse(JSON.stringify(errors));
        return err[field].join();
    }
};

export const getConcatenatedErrorMessages = function (error) {
    let concatenatedErrors = "";
    if (error.response && error.response.data && error.response.data.errors) {
        const entries = Object.entries(error.response.data.errors);

        entries.forEach((entry) => {
            const entryVal: any = entry[1];
            entryVal.forEach((val) => {
                concatenatedErrors += val;
            });
        });
    } else if (
        error.response &&
        error.response.data &&
        error.response.data.message === "This action is unauthorized."
    ) {
        concatenatedErrors = "This action is unauthorized";
    } else {
        return error;
    }
    return concatenatedErrors;
};

export const getFilenameFromPath = function (fullPath) {
    if (fullPath) {
        return fullPath.split("/").pop();
    } else {
        return null;
    }
};

import {
    mdiEye,
    mdiBullseye,
    mdiCheckCircleOutline,
    mdiAlertOctagon,
} from "@mdi/js";

export const getTreeIcon = function (type) {
    if (type.slug == "vision") {
        return mdiEye;
    } else if (type.slug == "goal") {
        return mdiBullseye;
    } else if (type.slug == "action") {
        return mdiCheckCircleOutline;
    } else if (type.slug == "challenge") {
        return mdiAlertOctagon;
    }
};
