<script functional>
import { marked } from "marked";

import { replaceEmojis } from "../../../../utils/string-functions";

export default {
  name: "slack-section",
  props: {
    block: {
      type: Object,
    },
  },
  render: function (createElement) {
    // if there are emojis, we need to replace them
    this.$props.block.text.text = replaceEmojis(this.$props.block.text.text);

    if (this.$props.block.text.type == "mrkdwn") {
      return createElement("div", {
        domProps: {
          innerHTML: marked(this.$props.block.text.text, { saninitize: true }),
        },
      });
    } else {
      return createElement(
        "div",
        {},
        marked(this.$props.block.text.text, { saninitize: true })
      );
    }
  },
};
</script>
