import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";

Vue.use(Vuex);

export interface Country {
    value: any;
    text: any;
}

export default new Vuex.Store({
    state: {
        auth: {
            loggedIn: {
                id: null,
                email: null,
                first_name: null,
                last_name: null,
                slack_access_token: null,
            }, // set the user that's logged in, or null if not logged in
            roles: {
                administrator: false,
                moderator: false,
                facilitator: false,
                countrycoordinator: false,
                user: false,
            },
        },
        countryItems: null, // A list of all countries
        sdgItems: null, // A list of sustainable development goals
        intended: null, // where a user tried to end up
        myTreeActions: null, // A list of actions that I need to complete
        myTrees: null, // Trees that I am involved in
        rolesItems: null, //A list of roles
        slack: {
            users: null,
            channel: null,
            channels: [],
            conversations: {
                messages: [],
                response_metadata: null,
            },
        },
    },
    // getters: {
    //   intended() {
    //     this.state ? this.state.intended : null;
    //   },
    //   auth() {
    //     this.state ? this.state.auth : null;
    //   },
    //   slack() {
    //     this.state ? this.state.slack : null;
    //   },
    // },
    mutations: {
        setSlackChannels(state, data) {
            data.forEach((element) => {
                if (!element.is_archived) {
                    state.slack.channels.push(element);
                }
            });
        },
        setSlackUsers(state, data) {
            state.slack.users = data;
        },
        addSlackConversations(state, { channelName, data }) {
            state.slack.channel = channelName;
            // let messages = state.slack.conversations.messages;

            data.messages.forEach((message) => {
                // Only add if the message doesn't already exist
                const hasMessage = state.slack.conversations.messages.find(
                    (element) => element.ts === message.ts
                );
                if (!hasMessage) {
                    state.slack.conversations.messages.push(message);
                }
            });

            // let sorted = messages.sort((a, b) => (a.ts > b.ts ? 1 : -1));
            // order first, then insert
            // state.slack.conversations.messages = sorted;
            state.slack.conversations.response_metadata =
                data.response_metadata;
        },
        setSlackAppendConversation(state, data) {
            if (!state.slack.conversations.messages) {
                return null;
            }

            const elementToAppend = data;
            const messages = JSON.parse(
                JSON.stringify(state.slack.conversations.messages)
            );

            // if this is a message that has been changed, removed the original message and append
            if (
                elementToAppend.previous_message &&
                elementToAppend.type == "message" &&
                elementToAppend.subtype &&
                elementToAppend.subtype == "message_changed"
            ) {
                let elementToBeUpdated = -1;

                messages.find((message, index) => {
                    if (
                        message.ts &&
                        elementToAppend.previous_message.ts &&
                        message.ts == elementToAppend.previous_message.ts
                    ) {
                        elementToBeUpdated = index;
                    }
                });

                if (elementToBeUpdated > 0) {
                    messages[elementToBeUpdated] = data.message;
                    state.slack.conversations.messages = messages;

                    return;
                }
            }

            // append only if the message is not already in.  Use ts (timestamp), as it's unique for a channel
            const found = messages.find((message) => {
                if (message.ts && elementToAppend.event_ts) {
                    return message.ts == elementToAppend.event_ts;
                }
            });
            if (!found) {
                messages.push(elementToAppend);
                state.slack.conversations.messages = messages;
            }
        },
        setTreeBranches(state, { tree, data }) {
            state.myTrees.find((treeEl) => {
                return treeEl.uuid === tree.uuid;
            }).branches = data;
        },
        setMyTrees(state, data) {
            state.myTrees = data;
        },
        setMyTreeActions(state, data) {
            state.myTreeActions = data;
        },
        setSDGItems(state, data) {
            state.sdgItems = data;
        },
        setRolesItems(state, data) {
            state.rolesItems = data;
        },
        setCountryItems(state, data) {
            state.countryItems = data;
        },
        setIntended(state, data) {
            state.intended = data;
        },
        setLoggedIn(state, data) {
            if (data.roles) {
                data.roles.forEach((role) => {
                    if (role.name == "administrator") {
                        state.auth.roles.administrator = true;
                    } else if (role.name == "moderator") {
                        state.auth.roles.moderator = true;
                    } else if (role.name == "facilitator") {
                        state.auth.roles.facilitator = true;
                    } else if (role.name == "countrycoordinator") {
                        state.auth.roles.countrycoordinator = true;
                    } else if (role.name == "user") {
                        state.auth.roles.user = true;
                    }
                });
            }

            // The role details as returned by the server is not needed, as the essence is captured in state.auth.roles
            delete data.roles;
            state.auth.loggedIn = data;
        },
    },
    actions: {
        slackChannels(context) {
            if (context.state.slack.channels.length === 0) {
                return Vue.prototype.$http
                    .get("/api/slack/channels")
                    .then((response) => {
                        context.commit("setSlackChannels", response.data);

                        // load the #communitywall once we have the channels
                        context.dispatch("slackConversations", "communitywall");
                    })
                    .catch(() => {});
            }
        },
        slackUsers(context) {
            if (!context.state.slack.users) {
                return Vue.prototype.$http
                    .get("/api/slack/users.list")
                    .then((response) => {
                        context.commit("setSlackUsers", response.data);
                    })
                    .catch(() => {});
            }
        },
        slackConversations(context, channelName) {
            // if (context.state.slack.conversations.messages.length == 0) {
            // let url = "/api/slack/channel/" + channelName;
            // if (
            //     context.state.slack.conversations.response_metadata &&
            //     context.state.slack.conversations.response_metadata.next_cursor
            // ) {
            //     url +=
            //         "?cursor=" +
            //         context.state.slack.conversations.response_metadata
            //             .next_cursor;
            // }

            const channels = JSON.parse(
                JSON.stringify(context.state.slack.channels)
            );
            const channelObjFromName = channels.find(
                (element) => element.name == channelName
            );

            const data = new FormData();
            data.append(
                "token",
                context.state.auth.loggedIn.slack_access_token
            );
            data.append("channel", channelObjFromName.id);
            data.append("limit", "5");

            const url = "https://slack.com/api/conversations.history";
            if (
                context.state.slack.conversations.response_metadata &&
                context.state.slack.conversations.response_metadata.next_cursor
            ) {
                data.append(
                    "cursor",
                    context.state.slack.conversations.response_metadata
                        .next_cursor
                );
            }

            delete Vue.prototype.$http.defaults.headers.common[
                "X-Requested-With"
            ];
            delete Vue.prototype.$http.defaults.headers.common["X-CSRF-TOKEN"];

            return Vue.prototype.$http
                .post(url, data)
                .then((response) => {
                    const data = response.data;
                    context.commit("addSlackConversations", {
                        channelName,
                        data,
                    });
                })
                .catch(() => {});
            // }
        },

        slackAppendConversation(context, data) {
            context.commit("setSlackAppendConversation", data);
        },

        myTrees(context) {
            if (!context.state.myTrees) {
                return Vue.prototype.$http
                    .get("/social/api/coaching/my-trees")
                    .then((response) => {
                        context.commit("setMyTrees", response.data);
                    })
                    .catch(() => {});
                }
        },

        myTreeActions(context) {
             // if (!context.state.myTreeActions) {
            return Vue.prototype.$http
                .get("/social/api/coaching/my-tree-actions")
                .then((response) => {
                    context.commit("setMyTreeActions", response.data);
                })
                .catch(() => {});
            //            }
        },

        refreshTreeBranches(context, tree) {
            return Vue.prototype.$http
                .get("/social/api/coaching/tree/" + tree.uuid + "/branches")
                .then((response) => {
                    context.commit("setTreeBranches", {
                        tree: tree,
                        data: response.data,
                    });
                })
                .catch(() => {});
        },

        countryItems(context) {
            if (!context.state.countryItems) {
                return Vue.prototype.$http
                    .get("/api/countries")
                    .then((response) => {
                        const countryItems: Array<Country> = [];
                        const entries = Object.entries(response.data);
                        entries.forEach((element) => {
                            countryItems.push({
                                value: element[0],
                                text: element[1],
                            });
                        });
                        context.commit("setCountryItems", countryItems);
                    })
                    .catch(() => {});
            }
        },
        sdgItems(context) {
            if (!context.state.sdgItems) {
                return Vue.prototype.$http
                    .get("/api/sdgs")
                    .then((response) => {
                        context.commit("setSDGItems", response.data);
                    })
                    .catch(() => {});
            }
        },
        intended(context, intended) {
            context.commit("setIntended", intended);
        },
        checkAuth(context) {
            return Vue.prototype.$http
                .get("/social/api/user/logged-in")
                .then((response) => {
                    context.commit("setLoggedIn", response.data);
                })
                .catch(() => {
                    context.commit("setLoggedIn", false);
                });
        },
        rolesItems(context) {
            return Vue.prototype.$http
                .get("/social/api/roles")
                .then((response) => {
                    context.commit("setRolesItems", response.data);
                })
                .catch(() => {});
        },
    },
    modules: {},
});
