<template>
  <div>
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <a href="/v" title="Back to homepage">
          <router-link
            :to="{
              name: 'BackendHome',
            }"
          >
            <v-img
              class="ml-4 mt-3 mb-3"
              src="../../src/assets/logo.png"
              alt="Innojo logo"
              style="width: 150px"
            ></v-img>
          </router-link>
        </a>

        <v-list rounded class="mb-5">
          <v-list-item-group color="primary">
            <v-list-item
              link
              v-for="link in links"
              :key="link.text"
              :to="link.route"
              :dusk="link.dusk"
            >
              <v-list-item-icon class="text-left mr-3">
                <v-icon class="black--text">{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ link.text }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="hidden-md-and-up mb-10" @click="launchHelp()">
              <v-list-item-icon class="text-left mr-3">
                <v-icon class="red--text">{{
                  icons.mdiHelpCircleOutline
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content style="position: relative">
                Help
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app color="white" light>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-spacer></v-spacer>
        <v-row align="center" style="max-width: 48px" class="mr-2">
          <v-menu offset-y v-if="auth.loggedIn.id">
            <template v-slot:activator="{ on }">
              <v-avatar v-on="on" class="pointer" dusk="topnavProfile">
                <img
                  width="48"
                  height="48"
                  :src="
                    getResizedImageLocal(auth.loggedIn.avatar_location, 48, 48)
                  "
                  :alt="
                    auth.loggedIn.first_name + ' ' + auth.loggedIn.last_name
                  "
                />
              </v-avatar>
            </template>
            <v-list dense>
              <v-list-item @click="profile()" style="min-width: 100px">
                <v-list-item-title>My profile</v-list-item-title>
              </v-list-item>
              <v-list-item @click="projects()" style="min-width: 100px">
                <v-list-item-title>My projects</v-list-item-title>
              </v-list-item>
              <v-list-item @click="slackMessages()" style="min-width: 100px">
                <v-list-item-title>My Slack messages</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="myProgramSessions()"
                style="min-width: 100px"
                v-if="userBelongsToATrainerCohort()"
              >
                <v-list-item-title>My training</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout()" style="min-width: 100px">
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-app-bar>

      <v-main>
        <router-view
          class="mb-10 mt-5 pl-7 pr-7"
          style="max-width: 1200px; width: 100%"
        />
        <v-bottom-navigation
          v-model="value"
          :background-color="color"
          dark
          fixed
        >
          <v-btn :to="{ name: 'BackendHome' }">
            <span>Home</span>

            <v-icon
              class="v-icon notranslate v-icon--link v-icon--svg theme--light"
              >{{ icons.mdiHome }}</v-icon
            >
          </v-btn>

          <v-btn
            :to="{
              name: 'BackendConnect',
              params: {
                type: 'search',
              },
            }"
          >
            <span>Connect</span>

            <v-icon
              class="v-icon notranslate v-icon--link v-icon--svg theme--light"
              >{{ icons.mdiAccountSearch }}</v-icon
            >
          </v-btn>

          <v-btn :to="{ name: 'BackendCoachingTreeLoader' }">
            <span>{{ treeCaption }}</span>

            <v-icon
              class="v-icon notranslate v-icon--link v-icon--svg theme--light"
              >{{ icons.mdiPineTree }}</v-icon
            >
          </v-btn>

          <v-btn
            :to="{
              name: 'BackendProfileTimeline',
              params: { username: auth.loggedIn.username },
            }"
          >
            <span>Timeline</span>
            <v-badge
              color="red"
              :content="myTreeActions"
              :value="myTreeActions > 0"
            >
              <v-icon
                class="v-icon notranslate v-icon--link v-icon--svg theme--light"
                >{{ icons.mdiTimeline }}</v-icon
              >
            </v-badge>
          </v-btn>
        </v-bottom-navigation>
      </v-main>

      <v-footer class="footer grey darken-1" app>
        <span class="white--text"
          >&copy;
          <a href="https://www.dotrust.org/" target="_blank" class="white--text"
            >DOT</a
          ></span
        >
        <v-spacer></v-spacer>
        <div style="position: relative" class="hidden-sm-and-down">
          <v-btn
            class="mx-2"
            fab
            color="pink"
            style="position: absolute; top: -100px; right: 0px"
            ref="helpButton"
            @click.stop="launchHelp()"
          >
            <template v-slot:default>
              <v-icon class="white--text" small>{{
                icons.mdiHelpCircleOutline
              }}</v-icon
              ><br />
              <small class="white--text">Help</small>
            </template>
          </v-btn>
        </div>
      </v-footer>
      <help ref="help"></help>
      <snackbar ref="snackbar"></snackbar>
    </v-app>
  </div>
</template>

<script>
import Help from "../components/Generic/Help";
import Snackbar from "../components/Snackbar/Snackbar";
import { getResizedImage } from "../helpers";
import {
  mdiHome,
  mdiAccountSearch,
  mdiPineTree,
  mdiRun,
  mdiTimeline,
  mdiAccount,
  mdiCommentAccountOutline,
  mdiLogout,
  mdiChartAreaspline,
  mdiHelpCircleOutline,
} from "@mdi/js";

import {
  authed_user_listener,
  tree_listener,
  slack_listener,
} from "../utils/channel-listener";

export default {
  components: {
    snackbar: Snackbar,
    help: Help,
  },
  props: {
    source: String,
  },
  data: () => ({
    icons: {
      mdiHome,
      mdiAccountSearch,
      mdiPineTree,
      mdiRun,
      mdiTimeline,
      mdiAccount,
      mdiCommentAccountOutline,
      mdiLogout,
      mdiChartAreaspline,
      mdiHelpCircleOutline,
    },
    drawer: null,
    value: 1,
  }),
  methods: {
    // Check if the user belongs to a cohort that has a type of "trainers"
    async userBelongsToATrainerCohort() {
      await this.$http
        .get("/social/api/cohort/is-user-part-of-type", {
          params: { type: "trainers" },
        })
        .then((response) => {
          return parseInt(response.data) > 0 ? true : false;
        })
        .catch(() => {});

      return false;
    },
    launchHelp() {
      this.$refs.help.dialog = true;
    },
    profile() {
      this.$router.push({
        name: "BackendProfileEdit",
        params: { username: this.auth.loggedIn.username },
      });
    },
    projects() {
      this.$router.push({
        name: "BackendProjectList",
        params: { username: this.auth.loggedIn.username },
      });
    },
    slackMessages() {
      this.$router.push({
        name: "BackendSlackMessages",
      });
    },
    myProgramSessions() {
      this.$router.push({
        name: "BackendProgramSessions",
      });
    },
    search() {
      this.$router.push({
        name: "BackendConnect",
        params: {
          type: "search",
        },
      });
    },

    logout() {
      this.$router.push({
        name: "FrontendLogout",
      });
    },
    getResizedImageLocal(src, width, height) {
      return getResizedImage(src, width, height, ["c_fill", "g_face"]);
    },
  },
  mounted() {
    authed_user_listener(this);
    tree_listener(this);
    slack_listener(this);
  },
  computed: {
    treeCaption() {
      if (!this.$store.state.myTrees) {
        return "Tree";
      }
      return this.$store.state.myTrees.length > 1 ? "Trees" : "Tree";
    },
    color() {
      switch (this.value) {
        case 0:
          return "blue darken-2";
        case 1:
          return "blue darken-2";
        case 2:
          return "teal";
        case 3:
          return "blue darken-2";
        default:
          return "blue darken-2";
      }
    },
    myTreeActions() {
      let count = 0;
      if (this.$store.state.myTreeActions) {
        this.$store.state.myTreeActions.forEach((element) => {
          var parsedobj = JSON.parse(JSON.stringify(element));
          count += parsedobj.items.length;
        });
      }
      return count;
    },
    auth() {
      return this.$store.state.auth;
    },
    links() {
      let self = this;
      let ret = [
        {
          icon: this.icons.mdiHome,
          text: "Home",
          route: self.$router.resolve({ name: "BackendHome" }).route,
          dusk: "sidenavHome",
        },
        {
          icon: this.icons.mdiAccountSearch,
          text: "Connect",
          route: self.$router.resolve({
            name: "BackendConnect",
            params: {
              type: "search",
            },
          }).route,
          dusk: "sidenavSearch",
        },
        {
          icon: this.icons.mdiPineTree,
          text: this.treeCaption,
          route: self.$router.resolve({ name: "BackendCoachingTreeLoader" })
            .route,
          dusk: "sidenavTree",
        },
        {
          icon: this.icons.mdiTimeline,
          text: "Timeline",
          route: self.$router.resolve({
            name: "BackendProfileTimeline",
            params: { username: this.auth.loggedIn.username },
          }).route,
          dusk: "sidenavTimeline",
        },
        {
          icon: this.icons.mdiAccount,
          text: "My Profile",
          route: self.$router.resolve({
            name: "BackendProfileEdit",
            params: { username: this.auth.loggedIn.username },
          }).route,
          dusk: "sidenavProfile",
        },
        {
          icon: this.icons.mdiAccount,
          text: "My Projects",
          route: self.$router.resolve({
            name: "BackendProjectList",
            params: { username: this.auth.loggedIn.username },
          }).route,
          dusk: "sidenavProject",
        },
      ];

      // We can assume if they have access to > 1 tree, they have coachees whos trees they can access, so add the navigation
      if (this.$store.state.myTrees && this.$store.state.myTrees.length > 1) {
        ret.push({
          icon: this.icons.mdiCommentAccountOutline,
          text: "My Coachees",
          route: self.$router.resolve({ name: "BackendCoachees" }).route,
          dusk: "sidenavCoachees",
        });
      }

      ret.push({
        icon: this.icons.mdiLogout,
        text: "Logout",
        route: self.$router.resolve({ name: "FrontendLogout" }).route,
        dusk: "sidenavLogout",
      });

      if (this.auth.roles.countrycoordinator || this.auth.roles.administrator) {
        ret.push({
          icon: this.icons.mdiChartAreaspline,
          text: "Reports",
          route: self.$router.resolve({ name: "CoachingReporting" }).route,
        });
      }

      return ret;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  text-size-adjust: 5;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.v-item-group.v-bottom-navigation--absolute,
.v-item-group.v-bottom-navigation--fixed {
  z-index: 8;
}
</style>
