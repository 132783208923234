/**
 * Find emojis expressed as :joy: and replace them with emoji icons from the v-emoji-picker
 */

import { emojisDefault } from "v-emoji-picker/src/utils/emojis";

export function replaceEmojis(text) {
    // hack, due to the fact that "rolling_on_the_floor_laughing" does not exist as an alias in the v-emoji-picker library.  Reached out to developers.
    let regex = new RegExp("rolling_on_the_floor_laughing", "g");
    text = text.replace(regex, "rofl");
    regex = new RegExp("smiling_face_with_3_hearts", "g");
    text = text.replace(regex, "heart_eyes");

    const start = text.indexOf(":");

    if (start >= 0) {
        const end = text.indexOf(":", start + 1);
        if (end) {
            const emojiInText = text.substring(start, end + 1);

            const emoji = emojisDefault.find((element) => {
                return element.aliases.find((alias) => {
                    if (":" + alias + ":" === emojiInText) {
                        return element;
                    }
                });
            });

            if (emoji) {
                const regex = new RegExp(emojiInText, "g");

                text = text.replace(regex, emoji.data);
                return replaceEmojis(text);
            }
        }
    }
    return text;
}

export function replaceNewLineWithBR(text) {
    return text.replace(/(?:\r\n|\r|\n)/g, "<br />");
}

export function truncate(text, length, suffix = "..", type = "characters") {
    if (!text) {
        return null;
    }

    if (type == "characters") {
        if (text.length < length) {
            return text;
        }
        return text.substring(0, length) + suffix;
    } else if (type == "words") {
        const array = text.split(" ");
        if (array.length < length) {
            return text;
        } else {
            return array.splice(0, length).join(" ") + suffix;
        }
    }
}
