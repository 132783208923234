export function authed_user_listener(_this) {
    const self = _this;

    self.$eventHub.$on("notification", function (notification) {
        if (notification.type == "broadcast.CoachingTreeBranchUpdated") {
            if (notification.branch.type.slug == "action") {
                self.$store.dispatch("myTreeActions");
            }
        }
    });
}
export function tree_listener(_this) {
    const self = _this;

    self.$eventHub.$on("notification", function (notification) {
        if (notification.type == "broadcast.CoachingTreeBranchUpdated") {
            // // Refresh the tree if we get a notification to that extent
            self.$eventHub.$emit(
                "message",
                notification.user.first_name + " edited the tree"
            );
            self.$store.dispatch("refreshTreeBranches", notification.tree);
        }
    });
}
export function slack_listener(_this) {
    const self = _this;

    self.$eventHub.$on("notification", function (notification) {
        if (notification.type == "broadcast.SlackEventCreated") {
            if (notification.event) {
                // If event data exists, use it, else assume that the push is > 10Kb so make a get request to get the detail
                const json = JSON.parse(notification.event.data_received);

                if (
                    json.event &&
                    json.event.type &&
                    json.event.type == "message"
                ) {
                    self.$store.dispatch("slackAppendConversation", json.event);
                }
            } else {
                // If the event data exists, push it onto the stack, else we can assume that it exceeded the 10k max from pusher, in which case we make a request to the uuid of the event
                self.$http
                    .get("/api/slack/event/" + notification.uuid)
                    .then((response) => {
                        const json = JSON.parse(response.data.data_received);
                        self.$store.dispatch(
                            "slackAppendConversation",
                            json.event
                        );
                    })
                    .catch(() => {});
            }

            // // Refresh the tree if we get a notification to that extent
            // if (notification.branch.tree_id == self.tree.id) {
            // self.$eventHub.$emit(
            //   "message",
            //   notification.user.first_name + " edited the tree"
            // );
            // self.$store.dispatch("refreshTreeBranches", notification.tree);
            //        self.getTreeBranches();
            // }
        }
    });
}
