<template functional>
  <span class="text-left" data-type="rich-text-section">
    <!-- RichTextSection
    {{ props.block }} -->
    <span v-if="props.block.elements">
      <span v-for="(element, index) in props.block.elements" :key="index">
        <slack-type-selector :block="element"></slack-type-selector>
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: "slack-rich-text-section",
  props: {
    block: {
      type: Object,
    },
  },
};
</script>
