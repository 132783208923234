import { getOrdinalSuffix } from "../utils/number-functions";

// Takes a date object and returns 1 Jan 2000 00:00
export function getFormattedDate(date) {
    return (
        date.getDate() +
        " " +
        getMonthAsString(date).substr(0, 3) +
        " " +
        date.getFullYear() +
        " " +
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
    );
}

export function getDayOfTheWeekAsString(date) {
    let weekday = new Array(7);
    weekday = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    return weekday[date.getDay()];
}

export function getMonthAsString(date) {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    return monthNames[date.getMonth()];
}

export function getTimestampHeading(ts) {
    const date = new Date(ts.split(".")[0] * 1000);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (
        date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
    ) {
        return "Today";
    } else if (
        date.getDate() == yesterday.getDate() &&
        date.getMonth() == yesterday.getMonth() &&
        date.getFullYear() == yesterday.getFullYear()
    ) {
        return "Yesterday";
    } else {
        return (
            getDayOfTheWeekAsString(date) +
            ", " +
            getMonthAsString(date) +
            " " +
            getOrdinalSuffix(date.getDate())
        );
    }
}
