declare global {
    interface Window {
        Echo: any;
        Pusher: any;
    }
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";

const VueLuxon = require("vue-luxon");

Vue.use(VueLuxon, {
    serverZone: "utc",
    serverFormat: "iso",
    clientZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    clientFormat: "locale",
    localeLang: "en",
    localeFormat: {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    },
});

// Only load analytics on production
if (process.env.NODE_ENV === "production") {
    Vue.use(
        VueGtag,
        {
            config: { id: "UA-120286614-2" },
        },
        router
    );
}

import axios from "axios";
import axiosRetry from "axios-retry";
axiosRetry(axios, {
    retries: 3,
    retryDelay: (retryCount) => {
        return retryCount * 1000;
    },
});

Vue.prototype.$http = axios;

import "./css/custom.css";

import vuetify from "./plugins/vuetify";

const Bugsnag = require("@bugsnag/js");
const BugsnagPluginVue = require("@bugsnag/plugin-vue");

// import Bugsnag from "@bugsnag/js";
// import BugsnagPluginVue from "@bugsnag/plugin-vue";

Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    //    otherOptions: {},
    onError: function (event) {
        // Log the user data if available
        const loggedIn = store.state.auth.loggedIn;
        if (loggedIn) {
            event.setUser(
                loggedIn.id,
                loggedIn.email,
                loggedIn.first_name + " " + loggedIn.last_name
            );
        }
    },
});
Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);

import linkify from "vue-linkify";
Vue.directive("linkified", linkify);

// import VueClipboard from "vue-clipboard2";
// Vue.use(VueClipboard);

Vue.config.productionTip = false;

Vue.prototype.$eventHub = new Vue(); // Global event bus

import Echo from "laravel-echo";
window.Pusher = require("pusher-js");

import backend from "./layouts/backend.vue";
Vue.component("backend-layout", backend);
import frontend from "./layouts/frontend.vue";
Vue.component("frontend-layout", frontend);
import none from "./layouts/none.vue";
Vue.component("none-layout", none);

import VueResizeText from "vue-resize-text";
Vue.use(VueResizeText);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

// Needs to be registered globally due to "Circular References" => https://vuejs.org/v2/guide/components-edge-cases.html
import SlackTypeSelector from "./components/Slack/Message/TypeSelector.vue";
Vue.component("slack-type-selector", SlackTypeSelector);

axios.get("/v2loader").then((response) => {
    store.commit("setSDGItems", response.data.sdgs);
    store.commit("setRolesItems", response.data.roles);
    store.commit("setLoggedIn", response.data.user);
    interface Country {
        value: any;
        text: any;
    }
    const countryItems: Array<Country> = [];
    const entries = Object.entries(response.data.countries);
    entries.forEach((element) => {
        countryItems.push({
            value: element[0],
            text: element[1],
        });
    });
    store.commit("setCountryItems", countryItems);

    Vue.prototype.$http.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-TOKEN": response.data.csrf,
    };

    window.Echo = new Echo({
        broadcaster: "pusher",
        key: process.env.VUE_APP_PUSHER_APP_KEY,
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        encrypted: true,
        auth: {
            headers: {
                "X-CSRF-TOKEN": response.data.csrf,
            },
        },
    });

    if (response.data.user.id) {
        window.Echo.private(
            "App.Models.Auth.User." + response.data.user.id
        ).notification((notification) => {
            Vue.prototype.$eventHub.$emit("notification", notification);
        });
    }

    new Vue({
        vuetify,
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app");
});

import "./filters";
