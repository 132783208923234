<template>
  <v-app id="inspire">
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="white"
      dark
    >
      <router-link
        :to="{
          name: 'FrontendHome',
        }"
      >
        <v-img
          class="ml-4 mt-3 mb-3"
          src="../../src/assets/logo.png"
          alt="Innojo logo"
          style="width: 150px"
        ></v-img>
      </router-link>

      <v-spacer></v-spacer>
      <!--
      <v-btn
        dusk="signupTopButton"
        rounded
        :to="{ name: 'FrontendRegistration' }"
        class="mr-2 primary"
      >
        Sign up
      </v-btn> -->
      <v-btn
        dusk="loginTopButton"
        rounded
        :to="{ name: 'FrontendLogin' }"
        class="grey mr-3"
      >
        Login
      </v-btn>
    </v-app-bar>
    <v-container class="mt-10 pl-0 pr-0" style="max-width: 2000px">
      <router-view />
    </v-container>
    <v-footer class="font-weight-medium">
      <v-col class="text-center" cols="12">
        <a href="https://dotrust.org" target="_blank"
          ><strong>Digital Opportunity Trust</strong></a
        ></v-col
      >
    </v-footer>
  </v-app>
</template>

<script>
export default {
  mounted() {
    let self = this;

    let isPublicProfileOrProjectBeingAccessed =
      window.location.pathname.indexOf("/v/f/profile/") === 0 ||
      window.location.pathname.indexOf("/v/f/project/") === 0;

    if (self.auth.loggedIn.id && !isPublicProfileOrProjectBeingAccessed) {
      self.$router.push({
        name: "BackendHome",
      });
    }

    // let self = this;
    // this.$http.get("/v2loader").then((response) => {
    //   let isPublicProfileOrProjectBeingAccessed =
    //     window.location.pathname.indexOf("/v/f/profile/") === 0 ||
    //     window.location.pathname.indexOf("/v/f/project/") === 0;
    //   if (response.data.user.id && !isPublicProfileOrProjectBeingAccessed) {
    //     self.$router.push({
    //       name: "BackendHome",
    //     });
    //   }
    // });
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    intended() {
      return this.$store.state.intended;
    },
  },
};
</script>
